<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>List Produksi</h2>
      <div class="d-flex justify-content-between mb-3">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <router-link to="/dashboards/add-labproduction">
            <b-button variant="primary-2"> Tambah Produksi </b-button>
          </router-link>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center">
          <span class="mr-3">Tanggal</span>
          <v-select
            class="mr-3"
            style="width: 150px"
            v-model="table.day"
            :options="dayOptions"
            @input="filterData"
            placeholder="Pilih Tanggal"
          />
          <label class="mr-3">Bulan</label>
          <v-select
            class="mr-3"
            style="width: 150px"
            v-model="table.month"
            :options="monthOptions"
            @input="filterData"
          />
          <label class="mr-3">Tahun</label>
          <b-form-select
            class="mr-3"
            style="width: 150px"
            v-model="table.year"
            :options="yearOptions"
            @input="filterData"
          />
        </div>
      </div>
    </b-col>
    <div class="col-12">
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="itemsFormatted"
        :fields="fields"
        :busy.sync="isBusy"
        responsive="sm"
        show-empty
      >
        <template v-slot:cell(status)="row">
          <b-badge
            :variant="row.item.status === 'passed' ? 'success' : 'danger'"
            class="w-100"
          >
            <span class="text-center">{{
              row.item.status === "passed" ? "Lulus" : "Gagal"
            }}</span>
          </b-badge>
        </template>
        <template v-slot:cell(Aksi)="row">
          <b-button
            variant="danger-2"
            @click="destroyLabProduction(row.item.id)"
          >
            <i class="ri-delete-bin-6-line mr-1" /> Hapus
          </b-button>
          <b-button
            class="mx-1"
            variant="info-1"
            :to="`/dashboards/detail-labproduction/${row.item.id}`"
          >
            <i class="ri-menu-fill mr-1" /> Detail
          </b-button>
          <b-button
            variant="success"
            :to="`/dashboards/print-labproduction/${row.item.id}`"
          >
            <i class="ri-printer-fill mr-1" />
            Cetak Label
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @input="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCol,
  BRow,
  BSpinner,
  BTable,
  BPagination,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import tableDataMixins from "../../../../mixins/tableDataMixins";

export default {
  name: "ListLabProduction",
  components: {
    BSpinner,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BBadge,
    vSelect,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      table: {
        items: [],
        search: "",
        day: null,
        month: null,
        year: null,
        currentPage: 1,
        perPage: 10,
        total: 0,
        debounceTimer: null,
      },
      fields: [
        { label: "Kode Kantong", key: "code_main_bag", sortable: true },
        { label: "Komponen", key: "component", sortable: true },
        { label: "Expired", key: "expired", sortable: true },
        { label: "Status", key: "status", sortable: true },
        { label: "Update Terakhir", key: "updated_at", sortable: true },
        { key: "Aksi", sortable: false },
      ],
      dayOptions: Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        text: i + 1,
      })),
      monthOptions: Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        text: i + 1,
      })),
      yearOptions: Array.from({ length: 15 }, (_, i) => ({
        value: 2010 + i,
        text: 2010 + i,
      })),
    };
  },
  computed: {
    labProductions() {
      return this.$store.state.manageLabProduction.labProductions;
    },
    totalPages() {
      return this.$store.state.manageLabProduction.totalPages;
    },
    isBusy() {
      return this.$store.state.manageLabProduction.isBusy;
    },
    itemsFormatted() {
      return this.table.items.map((item) => {
        const [datePart] = item.updated_at.split("T");
        const [year, month, day] = datePart.split("-");
        const formattedDate = `${day}-${month}-${year}`;
        return { ...item, updated_at: formattedDate };
      });
    },
  },
  methods: {
    async fetchData() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch("manageLabProduction/getLabProductions", {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
        year: this.table.year,
        month: this.table.month,
        day: this.table.day,
      });
      this.table.items = this.labProductions;
      this.table.total = this.totalPages;
    },
    async destroyLabProduction(id) {
      let formData = new FormData();
      formData.append("id", id);

      await this.$store.dispatch(
        "manageLabProduction/destroyLabProduction",
        formData
      );

      const index = this.table.items.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.table.items.splice(index, 1);
      }

      this.$bvToast.toast("Data Berhasil Dihapus", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    filterData() {
      this.fetchData();
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);
      this.table.debounceTimer = setTimeout(() => {
        this.fetchData();
      }, 500);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
